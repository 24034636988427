import React from "react";
import Header from "../components/header/header.js";
import LogoRoomCloud from "../assets/img/svg/logo-roomcloud.svg";

import "../assets/styles/pages/_functionalities.scss";

const OTA1 = [
  "A",
  "",
  "ABC Fiere ",
  "Ab-in-den-Urlaub  ",
  "Abreu  ",
  "Agoda  ",
  "Agriturismo-on-line ",
  "Aia Palace ",
  "AIC Travel ",
  "Airbnb  ",
  "Alfabookings ",
  "All Inclusive Hotel ",
  "Alpha Rooms  ",
  "Altoetting  ",
  "Amanda Tour ",
  "Amden-Weesen  ",
  "Amitour ",
  "Amrum-reservierung ",
  "Andermatt Gotthard  ",
  "Anxur Tours Group  ",
  "Appian Line  ",
  "Asbooking  ",
  "Asia Web Direct ",
  "AsiaRooms  ",
  "AsiaTravel ",
  "Atel ",
  "Atrapalo  ",
  "Auratours ",
  "Availpro ",
  "Avios ",
  "AxisData for Open Travel Service ",
  "",
  "",
  "",
  "B",
  "",
  "Back Pack Online ",
  "Badbirnbach ",
  "Bad-reichenhall  ",
  "Bakuun  ",
  "Bali Hotel Guide  ",
  "Bancotel ",
  "Barcelo viajes  ",
  "Barcelonaycatalunya  ",
  "Basel Tourismus  ",
  "Bbplanet ",
  "Bed and Snow  ",
  "Bed-and-breakfast.it ",
  "Bedandbreakfast.it ",
  "BedBookers ",
  "Berchtesgadener  ",
  "Best Spa Hotels ",
  "Bestday  ",
  "Bestrooms ",
  "BestWestern Member Web  ",
  "Biel-Seeland Tourismus  ",
  "BolognaWelcome  ",
  "Bonsahi ",
  "Boo Hotels In ",
  "Book N Deal ",
  "Bookando  ",
  "Bookassist  ",
  "Booked.net ",
  "BookerClub  ",
  "Bookhotels ",
  "Bookhotelsinrome.com  ",
  "Booking.com  ",
  "Booking Expert  ",
  "Booking Piemonte ",
  "BookingADV.com ",
  "BookinGay  ",
  "BookinGenius  ",
  "BookOn ",
  "Boscolo (marketplace) ",
  "BramanteTravel  ",
  "Bremen Tourismus ",
  "Bremenhaven-tourism  ",
  "Brilon-Tourismus  ",
  "Brillandia  ",
  "BSP Hotel  ",
  "Budapest hotel start ",
  "Budgetplaces  ",
  "Buesum  ",
  "Burghausen  ",
  "Butik Hotellier ",
  "",
  "",
  "",
  "C",
  "",
  "Caesardata  ",
  "Calypsobedbank.com ",
  "Canton de Vaud O.T.  ",
  "Carrani Tours ",
  "Carlton hotel group ",
  "Case-vacanza-italia.it ",
  "CentralR ",
  "CentralRez ",
  "Centralreservations.com  ",
  "CGHotel.es ",
  "ChannelPro ",
  "Charme & Relax ",
  "Charming Italy ",
  "Charming Sardinia ",
  "Chianti.it ",
  "Chiemsee-alpenland  ",
  "Ciaobnb ",
  "Ciao Guest  ",
  "Cisalpina ",
  "CIT Holydays  ",
  "Citybreak (Nozio)  ",
  "CityForDays ",
  "Cityzenbooking  ",
  "Classic international hotel ",
  "Clickbed ",
  "Conextur ",
  "Consolid Travel  ",
  "Consorzio Chianciano ",
  "Cote.azur.fr ",
  "CTrip Travel  ",
  "Cuxhaventours  ",
  "",
  "",
  "",
  "D",
  "",
  "Daniel Reyes  ",
  "Data Hotel  ",
  "Dedeman Hotels ",
  "Delphinet  ",
  "Despegar  ",
  "Destinations of the world  ",
  "DirectWithHotels ",
  "Dithmarschen-tourismus ",
  "Div-it ",
  "Dolomiti.org  ",
  "Domina Vip Travel ",
  "Donatello  ",
  "Dresden.de  ",
  "DTS ",
  "Dynam-Iq",
  "",
  "",
  "",
  "E",
  "",
  "Easyconsulting  ",
  "Easytobook  ",
  "EBKing ",
  "eBooking Hotel ",
  "Ecoworldhotel ",
  "eDreams ",
  "Emozioni Hotels ",
  "Emviajem.pt ",
  "EPlattform Graubünden  ",
  "Epoque Hotels ",
  "Escapio  ",
  "Etineries ",
  "Europlaya  ",
  "Eurores ",
  "Exclusively Hotels ",
  "Expedia  ",
  "Expo 2015 ",
  "Expotel ",
  "Extratime ",
  "Eyecom-Booking ",
  "",
  "",
  "",
  "F",
  "",
  "Farmbooking ",
  "Fastbooking ",
  "FavourItaly ",
  "Federal hotel ",
  "Fendivit ",
  "Feratel/ deskline  ",
  "Flims-Laax-Falera  ",
  "Finca Hotels  ",
  "FirenzeAlbergo  ",
  "Fisheyes ",
  "FlashBooking ",
  "Florence Promhotels ",
  "Fluege.de  ",
  "Foehr.de  ",
  "Freereservation ",
  "Freiburg  ",
  "Fribourg Region  ",
  "Furlong Incoming ",
  "Fvg ",
];

const OTA2 = [
  "G",
  "",
  "Ga.Ya ",
  "Gartour  ",
  "Geco ",
  "Genares ",
  "Genf Tourismus  ",
  "GetReadyRentals ",
  "GHRS – Unitravel ",
  "Globalia  ",
  "Globekey  ",
  "GNA.es ",
  "Gomio ",
  "Google HPA  ",
  "goVoyages ",
  "Graechen  ",
  "Gremi D’Hotels de Barcelona ",
  "Gateway WorldHotels ",
  "Gta hotels (metglobal) ",
  "Gta travel (travelcube/travelbound) ",
  "Gulliver.ie (Gullnet) ",
  "GuestIncoming ",
  "Guestlink ",
  "",
  "",
  "",
  "H",
  "",
  "HAlcon Viajes  ",
  "Hamburg.de  ",
  "Heidiland  ",
  "Holidaycity ",
  "Holidayinsider  ",
  "HolidayLettings ",
  "Homeaway ",
  "Hostelbookers ",
  "Hostelmania ",
  "HostelsClub  ",
  "Hosteltouristworld ",
  "Hostelworld ",
  "Hotel Contact ",
  "Hotel Direct  ",
  "Hotel GoGo ( reconline ) ",
  "Hotel.de  ",
  "Hotelbeds  ",
  "Hotel Booking Sky  ",
  "Hotelconnexions ",
  "Hotelclub ",
  "Hoteldirect.co.uk ",
  "Hoteliers.com ",
  "HotelKey  ",
  "Hotelnet  ",
  "Hotelonline  ",
  "Hotelreservierung ",
  "Hotelreservation  ",
  "HotelRez ",
  "Hotels.nl ",
  "Hotels4U.com ",
  "Hotelsarea ",
  "Hotelschart  ",
  "HotelsClick  ",
  "HotelsCombined  ",
  "Hotelscorse.fr  ",
  "Hotelspecials.nl ",
  "HotelTonight ",
  "HotelTools  ",
  "Hoteltravel ",
  "Hoterip  ",
  "Hotnet  ",
  "Hotusa  ",
  "Hotwire  ",
  "HReservation  ",
  "HRS  ",
  "Husum-tourismus ",
  "Hutchinson Priceline ",
  "",
  "",
  "",
  "I",
  "",
  "ICastelli.net  ",
  "Idiso ",
  "IHotelier.com ",
  "Ihotels.it ",
  "illaagomaggiore.com  ",
  "ImperatoreTravel  ",
  "In-Italia.dk  ",
  "In1solutions.com  ",
  "Infohostaltravelmkt (hotelisimo) ",
  "Initalia  ",
  "Inlobby ",
  "Instant worldbooking ",
  "Inthotels  ",
  "Irish welcome ",
  "Irs18  ",
  "ItalCamel ",
  "ItalyBookings",
  "ItalyHoliday  ",
  "ItalyHotels ",
  "ItalyItalia  ",
  "Its-Magellan ",
  "Itvacations ",
  "Itwg  ",
  "Ixpira ",
  "",
  "",
  "",
  "J",
  "",
  "JacTravel  ",
  "JDB ",
  "Jesolo OK ",
  "Jsetter ",
  "Jumbo Tours  ",
  "Justintime ",
  "",
  "",
  "",
  "K",
  "",
  "Keytel  ",
  "KingBooking  ",
  "Kuoni-connect ",
  "",
  "",
  "",
  "L",
  "",
  "Lago Maggiore E.T.  ",
  "Lastminute.com  ",
  "Laterooms  ",
  "Latestay ",
  "Leading Companies International ",
  "Leysin Tourisme  ",
  "LiForYou  ",
  "Life Apartments  ",
  "Logitavel  ",
  "Lohospo  ",
  "Loetschental Tourismus  ",
  "Love VDA ",
  "Loving Apartments ",
  "Low Cost Beds  ",
  "Lugano Turismo ",
  "Luxiabooking  ",
  "Luxuryres  ",
  "",
  "",
  "",
  "M",
  "",
  "Magicstay  ",
  "Mappamondo  ",
  "Marsol ",
  "Martehotels ",
  "Masserie Salento  ",
  "Mdtravelitaly ",
  "MediaWest  ",
  "Metglobal ",
  "Meunsterland-tourismus  ",
  "Miki Travel ",
  "Mirai Hotelsearch  ",
  "MisterFly ",
  "Moena ",
  "Montreux-Vevey Tourisme  ",
  "MrandMrsSmith ",
  "Muenchen.de ",
  "Myswitzerland ",
  "MyComp ",
  "",
  "",
  "",
  "N",
  "",
  "N2M  ",
  "Naturarena  ",
  "Navarino Services ",
  "NBCRS.com  ",
  "NetAffinity  ",
  "Nethotels ",
  "Netrez (Pegasus) ",
  "Netubi ",
  "Neuharlingensiel  ",
  "Nevertoomuch  ",
  "Nextel Travel XML ",
  "Nites Travel  ",
  "Nordfrieslandtourismus  ",
  "Novaresa ",
  "Nyceworld  ",
  "",
  "",
  "",
  "O",
  "",
  "ODIGEO Connect  ",
  "Offerhotel  ",
  "Oktogo ",
  "Olympia Viaggi  ",
  "Omega Tourism ",
  "OnEolie ",
  "OnIschia ",
  "Online Gds ",
  "OOnlyRooms  ",
  "Open Travel Service  ",
  "Openhospitality ",
  "OpenPro (Alliance-Réseaux) ",
  "Opodo ",
  "OpenTours  ",
  "Orbitz  ",
  "Ostrovock.ru ",
  "Ostseefiordischlei ",
];

const OTA3 = [
  "P",
  "",
  "Paginegialle ",
  "Partiendo  ",
  "Pegasus  ",
  "Perseus.mx (Mexican Wholsalers) ",
  "Personal booking (wrooms) ",
  "Perugia.com ",
  "Pilgo ",
  "Praettigau Tourismus  ",
  "Prestigia  ",
  "Price Travel ",
  "Pugliae.com  ",
  "Pxsol  ",
  "",
  "",
  "",
  "R",
  "",
  "Rakuten  ",
  "RatesToGo ",
  "Reconline ",
  "Regioneuropapark  ",
  "Reiseland-Brandenburg ",
  "Reisen.de  ",
  "RentRoom ",
  "Resanet ",
  "ReserVenice ",
  "Reserver  ",
  "Reservit ",
  "Restel  ",
  "RezOnline  ",
  "RezView (Pegasus) ",
  "Riccione Bike Hotels ",
  "Ricercahotel ",
  "Rimini Reservation ",
  "Roiback  ",
  "Roline System ",
  "Roma on line  ",
  "Romahotelservice ",
  "Roombooking service ",
  "Roomex.com  ",
  "RoomGenie  ",
  "RoomTrade ",
  "RSI Group ",
  "RTS – Realtime Travel Solution  ",
  "Ruhr-tourismus  ",
  "Russia In",
  "",
  "",
  "",
  "S",
  "",
  "Salento.it  ",
  "SalsaBooking ",
  "Sardegne.com ",
  "Sawadee ",
  "Savognin Tourismus im Surses ",
  "Sauerland  ",
  "Scharbeutz  ",
  "Schick Hotels  ",
  "Schwyz Tourismus  ",
  "Secure-HotelierV4 ",
  "Seneca Travel ",
  "Sehrstourism ",
  "Serhs  ",
  "Sestante  ",
  "Sfrecciando  ",
  "Sharebooking  ",
  "Siciliae.com ",
  "Sidetours ",
  "Siglinde-Fischer  ",
  "Simple Booking  ",
  "SitecResa  ",
  "Siteminder ",
  "SkiArt ",
  "Small Hotels ",
  "Smilo Orizonia ",
  "SouthEscapes ",
  "Spa Hotels Collection ",
  "Spacehotels ",
  "Spaghettihotel  ",
  "Special tours ",
  "Splendia ",
  "Stardeck ",
  "StartBooking.com ",
  "StyleHotels ",
  "Sunhotel  ",
  "Superbreak ",
  "Sybelio  ",
  "Synxis (globeres luxuryres) ",
  "Syshotelonline Ticket ",
  "Szallas.hu ",
  "",
  "",
  "",
  "T",
  "",
  "Tablet Hostel ",
  "The Best Spa Hotels  ",
  "ThinkHotels.com  ",
  "Thueringen Tourismus  ",
  "Tick Tab ",
  "Ticket.com  ",
  "Titanka  ",
  "Tobook  ",
  "Tomas Travel  ",
  "Tomorrow Travel  ",
  "Tourico ",
  "Tourisme de Barcelona ",
  "Tourisme Neuchatelois  ",
  "Travco ",
  "Travel europe ",
  "Travel Republic  ",
  "Travel.it ",
  "TTravelGate  ",
  "Travellero ",
  "Travellink ",
  "Travelocity Globalhotels ",
  "Traveloka  ",
  "Travelstay Network  ",
  "Travel Republic  ",
  "TripAdvisor TripConnect ",
  "Tripidea  ",
  "Tui ",
  "Turismo Piemonte ",
  "",
  "",
  "",
  "U",
  "",
  "Ultranet  ",
  "Unister  ",
  "Unitravel  ",
  "",
  "",
  "",
  "V",
  "",
  "Valle D’ Aosta Pass ",
  "Vas Travel ",
  "Vda  ",
  "VeneziaSi ",
  "Ventanagroup ",
  "Verona Booking  ",
  "Vertical Booking  ",
  "Verychic ",
  "Viaggiaedormi.com ",
  "Viaggiare Senza Glutine ",
  "Viaggiareweb ",
  "Viajes el Corte Ingles ",
  "Viajes Olympia ",
  "Viajes Urbis ",
  "Villars Tourisme  ",
  "Villas.com ",
  "VisitAlghero ",
  "VisitBerlin  ",
  "VisitEmiliaRomagna ",
  "VisitItalyTours ",
  "VisitTrentino ",
  "Visitwales ",
  "VivaFirenze  ",
  "",
  "",
  "",
  "W",
  "",
  "W-Reservation.com ",
  "Wallis Tourismus  ",
  "Wangerland  ",
  "Waytostay ",
  "Webresint ",
  "WebSawadee ",
  "WeekendCompany ",
  "Weekendesk  ",
  "WelcomeBeds  ",
  "WHL  ",
  "Willingen ",
  "Wimdu ",
  "Winterberg  ",
  "WishDays – Emozione3 ",
  "WithBooker ",
  "Wonderfull Sardinia ",
  "World2Meet  ",
  "Wookbook ",
  "Worldby ",
  "Worldres ",
  "Wotif ",
  "",
  "",
  "",
  "X",
  "",
  "XBookLite",
  "Xenia  ",
  "",
  "",
  "",
  "Y",
  "",
  "YourSpainHostel  ",
  "YourVoyager",
];

const Ota = () => {
  return (
    <>
      <Header title="OTA" metaDescription="OTA" />
      <div className="container ota">
        <div className="columns">
          <div className="column">
            <p className="description">
              {OTA1.map((i) => (
                <>
                  {i.length > 1 ? i : <b>{i}</b>}
                  <br />
                </>
              ))}
            </p>
          </div>
          <div className="column">
            <p className="description">
              {OTA2.map((i) => (
                <>
                  {i.length > 1 ? i : <b>{i}</b>}
                  <br />
                </>
              ))}
            </p>
          </div>
          <div className="column">
            <p className="description">
              {OTA3.map((i) => (
                <>
                  {i.length > 1 ? i : <b>{i}</b>}
                  <br />
                </>
              ))}
            </p>
          </div>
        </div>
        <div className="column">
          <div className="column is-12" style={{textAlign: "end"}}>
            <p style={{fontSize: "16px"}}>Powered by RoomCloud</p> 
            <br/>
            <img style={{width: "150px"}} src={LogoRoomCloud} alt="Logo RoomCloud" />
          </div>
        </div>
      </div>      
    </>
  );
};

export default Ota;
